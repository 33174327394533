<template>
  <div class="mt-5">
    <confirm-modal ref="confirmModal" />
    <h2 class="head-title">
      Manage privacy statement for CMS login
    </h2>
    <b-row>
      <b-col md="4">
        <p class="mb-2 mt-5">
          Privacy statement for CMS login:
        </p>
        <template v-if="!isShowForm">
          <p class="mb-4">
            <a :href="privacyStatementUrl">
              {{ privacyStatementUrl }}
            </a>
          </p>
          <b-button
            v-if="!isShowForm"
            variant="outline-info"
            class="mb-2 px-5"
            @click="showForm"
          >
            Edit
          </b-button>
        </template>
        <template v-else>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider
                v-slot="{ errors }"
                name="Url"
                rules="required|url"
              >
                <div class="form-group">
                  <input v-model="url" type="text" class="form-control" />
                  <span class="txt-error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <div>
                <button type="submit" class="btn btn-primary mt-3 mr-3 px-5">
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-outline-info mt-3 px-5"
                  @click="cancel()"
                >
                  Cancel
                </button>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
MODAL_CANCEL,
MODAL_SAVE,
OK_CONFIRM,
} from "../../../common/CommonUtils";
import { REGEX_URL } from "../../../constants/RegexRule";
import CountryPrivacyService from "../../../service/CountryPrivacyService";
import ConfirmModal from "../../common/ConfirmModal.vue";

extend("url", {
  validate(value) {
    var pattern = new RegExp(REGEX_URL, "i"); // fragment locator
    return pattern.test(value);
  },
  message: "Please enter a valid link.",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ConfirmModal,
  },
  data() {
    return {
      isShowForm: false,
      privacyStatementUrl: "",
      url: "",
      email: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    CountryPrivacyService.getPrivacyStatementUrl(this.currentUser).then(
      (response) => {
        if (response?.data?.privacyUrl) {
          this.privacyStatementUrl = response.data.privacyUrl;
        }
      }
    );
  },
  methods: {
    onSubmit() {
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          CountryPrivacyService.updatePrivacyStatementUrl(
            this.currentUser,
            this.url
          )
            .then((response) => {
              if (response?.data?.privacyUrl) {
                this.privacyStatementUrl = response.data.privacyUrl;
                this.showForm(false);
                this.openToast("Update successfully!", "success");
              }
            })
            .catch((reason) => {
              const data = reason.response.data;
              console.error(data);
            });
        }
      });
    },
    showForm(isShowForm = true) {
      this.isShowForm = isShowForm;
      if (isShowForm) {
        this.url = this.privacyStatementUrl;
      }
    },
    cancel() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          this.showForm(false);
        }
      });
    },
    openToast(message, type = "error") {
      this.$toast.open({
        message,
        type,
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
.txt-error {
  color: red;
}
</style>
